import React from "react";
import {useSelector} from "react-redux";
import {PageTitle} from "../../components";
import {TranslateString} from "../../lang-provider";

export const PrivacyPolicy = (props) => {
  const settings = useSelector((state) => state.aux?.data?.settings);

  return (
    <React.Fragment>
      <PageTitle>
        <TranslateString>Política de Privacidade</TranslateString>
      </PageTitle>
      <div>{settings?.privacy_policy?.value}</div>
      {/* <div className="mt-5">
        <a
          className="text-blue-600"
          href="https://nowtical.com/terms-and-conditions"
          target="_new">
          <TranslateString>Open Nowtical terms and conditions</TranslateString>
        </a>
      </div> */}
    </React.Fragment>
  );
};
